import './App.css';
import React from 'react';
import ForgeViewer from './components/ForgeViewer';
import MemberList from './components/MemberList';

function App() {
  return (
    <div className="App">
      <h1>Walnut Grove Cemetery Management</h1>
      <ForgeViewer />
      <MemberList />
    </div>
  );
}


export default App;
