import React, { useEffect } from 'react';
import axios from 'axios';

const ForgeViewer = () => {
  useEffect(() => {
    const options = {
      env: 'AutodeskProduction',
      getAccessToken: async (onGetAccessToken) => {
        try {
          const response = await axios.get('/api/forge/token');
          const { access_token, expires_in } = response.data;
          onGetAccessToken(access_token, expires_in);
        } catch (error) {
          console.error('Error fetching access token:', error);
        }
      },
    };

    window.Autodesk.Viewing.Initializer(options, () => {
      const viewerDiv = document.getElementById('forgeViewer');
      const viewer = new window.Autodesk.Viewing.GuiViewer3D(viewerDiv);
      viewer.start();

      const documentId = 'urn:YWRzay5vYmplY3RzOm9zLm9iamVjdDp3bGd2L3dsZ3YtdmVyMjAyMy5kd2c=';
      window.Autodesk.Viewing.Document.load(
        documentId,
        (doc) => {
          const defaultViewable = doc.getRoot().getDefaultGeometry();
          viewer.loadDocumentNode(doc, defaultViewable);
        },
        (errorMsg) => {
          console.error('Failed to load Forge document:', errorMsg);
        }
      );
    });
  }, []);

  return <div id="forgeViewer" style={{ width: '100%', height: '600px' }} />;
};

export default ForgeViewer;