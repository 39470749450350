import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MemberList = () => {
  const [members, setMembers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/members`);
        setMembers(response.data);
      } catch (err) {
        setError('Failed to fetch members');
        console.error(err);
      }
    };

    fetchMembers();
  }, []);

  return (
    <div>
      <h2>Member List</h2>
      {error && <p>{error}</p>}
      <ul>
        {members.map((member) => (
          <li key={member._id}>
            {member.firstName} {member.lastName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MemberList;